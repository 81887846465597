// section styles
.section {
    &__wrap {
        margin: auto;
        max-width: 960px;
        padding: 0 20px;

        @include tablet {
            display: flex;
        }


        @include large-desktop {
            max-width: 1160px;
        }
    }

    &__intro {
        align-items: center;
        background-image: url('../images/hero-banner.jpg');
        background-repeat: no-repeat;
        background-position: left;
        background-size: cover;
        display: flex;
        color: $white;
        height: 100vh;
        justify-content: center;
        position: relative;
        text-align: center;

        @include tablet {
            background-position: center;
            /*height: 600px;*/
        }


        &:after {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 20%, rgba(0, 0, 0, 0.25) 100%);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        h2 {
            @include desktop {
                margin-top: 150px;
            }
        }

        button[type="button"] {
            background-color: transparent;
            border: 0;
            color: inherit;
            cursor: pointer;
            font-size: em(15);
            margin-top: 100px;
            text-transform: uppercase;
            letter-spacing: 0.92em;

            &:after {
                @extend %icomoon;

                @include transition(transform, $animTime, $easeOutExpo);

                content: "\e904";
                display: block;
                font-size: 40px;
                margin: 10px auto 0;
                transform: none;
                width: 40px;
            }

            &:hover,
            &:focus {
                &:after {
                    transform: translateY(10px);
                }
            }
        }

        .section__wrap {
            display: block;
            z-index: 5;

            @include desktop {
                max-width: 50%;
            }
        }
    }

    &__carousel {
        @include normalise-list;

        .slick-arrow {
            background-color: transparent;
            border: 0;
            bottom: 0;
            color: $white;
            cursor: pointer;
            font-size: 40px;
            height: 40px;
            display: block;
            overflow: hidden;
            margin: auto;
            padding: 0;
            position: absolute;
            top: 0;
            width: 40px;

            @include desktop {
                font-size: 70px;
                height: 70px;
                width: 70px;
            }
        }

        .slick-prev {
            left: 10px;

            @include desktop {
                left: 125px;
                z-index:1000;
            }
        }

        .slick-next {
            right: 10px;

            @include desktop {
                right: 125px;
            }
        }

        .slick-dots {
            @include normalise-list;

            display: flex;
            justify-content: center;

            button[type="button"] {
                @include transition(opacity, $animTime, $easeOutExpo);

                background-color: $white;
                border: 0;
                border-radius: 50%;
                cursor: pointer;
                font-size: 0;
                height: 14px;
                margin: 0 4px;
                opacity: 0.5;
                width: 14px;

                &:hover,
                &:focus {
                    opacity: 1;
                }
            }

            .slick-active {
                button[type="button"] {
                    opacity: 1;
                }
            }
        }

        .slick-list {
            @include desktop {
                padding: 0 175px !important;
            }
        }

        .slick-slide {
            @include transition(opacity, $animTime, $easeOutExpo);

            padding: 0 50px;
            opacity: 0.25;

            @include tablet {
                padding: 0 80px;
            }


            &.slick-current {
                opacity: 1;
            }
        }
    }

    &__highlights {
        background-color: #4151ed;
        background-position: left;
        background-size: cover;
        color: $white;

        h3 {
            font-size: em(36);
            margin: 0;

            @include desktop {
                margin: 30px 0;
            }
        }

        p {
            font-size: em(18);
            margin-bottom: 30px;
        }

        .section__wrap {
            padding: 50vh 0 0;

            @include tablet {
                padding: 0;
            }
        }

        .section__col {
            background-color: #4151ed;
            padding: 60px 70px 60px 20px 40px 20px;
            position: relative;

            @include tablet {
                width: 40%;
            }


            &:before,
            &:after {
                display: none;

                @include tablet {
                    display: block;
                }
            }

            &:before {
                background-color: #4151ed;
                content: '';
                height: 100%;
                left: -100%;
                position: absolute;
                top: 0;
                width: 100%;
            }

            &:after {
                background-color: #4151ed;
                content: '';
                height: 100%;
                right: -100px;
                position: absolute;
                top: 0;
                transform: skew(14deg);
                width: 200px;
                z-index: 1;
            }
        }

        .section__content {
            padding: 30px 20px 0;
            position: relative;
            z-index: 5;

            @include tablet {
                padding: 30px 30px 0;
            }
        }

        .btn {
            background-color: #111e51;
            color: $white;
            display: inline-block;
            font-size: em(18);
            font-weight: $bold;
            margin: 0 auto 30px;
            padding: 15px 20px;
            width: auto;

            &:hover,
            &:focus {
                background-color: $white;
                color: #111e51;
            }
        }
    }

    &__projects {
        background-color: #1b2e74;
        color: $white;
        padding-top: 60px;
        padding-bottom: 40px;
        text-align: center;
        display:none;

        h3 {
            font-size: em(40);
            margin-bottom: 20px;
        }

        h4 {
            font-size: em(25);
        }

        p {
            font-size: em(18);
        }

        .section__wrap {
            display: block;
            max-width: none;

            @include desktop {
                padding: 0;
            }
        }

        .project {
            &__icons {
                @include normalise-list;

                display: flex;
                justify-content: center;
                margin-top: 30px;

                li {
                    @include material-shadow(1);

                    margin: 0 8px;
                    max-width: 44px;
                }

                img {
                    margin-bottom: 0;
                }
            }

            &__location {
                font-size: em(16);
                margin-top: 30px;
                margin-bottom: 40px;

                p {
                    font-size: inherit;
                    margin: 0;

                    &:before {
                        @extend %icomoon;

                        content: "\e909";
                        display: inline-block;
                        font-size: 12px;
                        margin-right: 5px;
                    }
                }

                span {
                    font-style: italic;
                }
            }
        }
    }

    &__services {
        // background-color: #1b2e74;
        background-color: #111e51;
        color: $white;

        h3 {
            font-size: em(25);
            margin: 20px 0;
        }

        .section__col {
            padding: 20px;
            text-align: center;

            @include tablet {
                padding: 60px 35px;
            }
        }

        .section__img {
            
            height: 80px;
            margin: auto;
            overflow: hidden;
            width: 80px;
        }
    }

    &__contact {
        background-color: #111e51;
        background-position: -60vw center;
        background-repeat: no-repeat;
        background-size: 100%;
        color: $white;
        padding-top: 60px;
        padding-bottom: 40px;
        text-align: center;

        h2 {
            font-size: em(40);
            margin: 0 0 10px;
        }

        p {
            font-size: em(18);
        }

        a {
            color: inherit;
        }

        hr {
            margin: 40px auto;
            width: 120px;
        }

        .section__wrap {
            flex-direction: column;
            padding: 0 20px;

            @include tablet {
                max-width: 50%;
                padding: 0;
            }
        }

        .cta {
            font-size: em(26);
            font-weight: $light;
        }

        .btn {
            background-color: $white;
            border: 1px solid $white;
            color: #111e51;
            cursor: pointer;
            display: inline-block;
            font-size: em(18);
            font-weight: $bold;
            margin: 10px auto 0;
            padding: 15px 20px;
            width: auto;

            &:hover,
            &:focus {
                background-color: #111e51;
                color: $white;
            }
        }
    }

    &__book {
        @include tablet {
            display: flex;
            margin-top: 75px;
        }

        @include desktop {
            margin-top: 200px;
        }

        .section__wrap {
            padding: 0;
        }

        .section__content {
            background-color: #111e51;
            color: $white;
            display: block;
            padding: 20px;

            @include tablet {
                margin-bottom: 80px;
                padding: 60px 70px 60px 130px;
                width: 50%;
            }

            h2 {
                margin-top: 0;
            }
        }

        .section__image {
            background-image: url('../images/background-form.jpg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 150px;
            position: relative;

            @include tablet {
                height: 700px;
                margin-top: 50px;
                right: -60px;
                width: 50%;
            }
        }
    }
}
