// breadcrumbs styles
.breadcrumbs {
    height: 100%;
    margin: auto;
    max-width: 960px;
    padding: 0 $gutter;
    position: relative;
    width: 100%;

    @include desktop {
        padding: 0 $gutterTablet;
    }

    @include large-desktop {
        max-width: 1160px;
    }

    ol {
        @include normalise-list;
    }

    li {
        display: inline-block;
        vertical-align: top;

        a {
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &:after {
            @extend %icomoon;

            content: "\e902";
            margin: 0 10px;
            position: relative;
            top: 3px;
        }

        &:last-child {
            font-weight: bold;

            &:after {
                content: "";
            }
        }
    }
}