// Variable settings for grids and what not
$baseFontSize: 16;
$defaultFontSize: 1.6em;
$gutter: 20px;
$gutterTablet: 15px;
$gutterDesktop: 15px;

// Breakpoints
$bpMaxMobile: 767px;
$bpMinTablet: 768px;
$bpMinDesktop: 980px;
$bpMaxDesktop: 1200px;

// Colors from Google's Material Design
$red: #f44336;
$pink: #e91e63;
$purple: #9c27b0;
$deepPurple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$lightBlue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$lightGreen: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deepOrange: #ff5722;
$brown: #795548;
$grey: #9e9e9e;
$blueGrey: #607d8b;
$black: #000000;
$white: #ffffff;

$border-color: #8c8c8c;

// Easing
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

// Animation Time
$animTime: 0.5s;
