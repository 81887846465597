// header styles
.header {
    @include material-shadow(1);

    background: #1b2e74;
    height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    @include desktop {
        @include material-shadow(0);

        background-color: transparent;
        height: auto;
        position: absolute;

        &.hide {
            top: -108px !important;
        }
    }
}

.logo {
    margin-top: 15px;
    padding: 0;
    position: absolute;
    z-index: 5;

    @include desktop {
        margin-top: 60px;
        position: relative;
    }


    a {
        color: $white;
        display: block;
        font-weight: $bold;
        line-height: 1;
        margin-bottom: 0;
        max-width: 100px;
        text-decoration: none;

        @include desktop {
            max-width: 210px;
        }

        svg {
            width: 100%;
        }
    }
}

.menu {
    @include transition(background-color, $animTime, $easeOutExpo);

    background-color: transparent;
    border: 0;
    height: 60px;
    padding: 8px;
    width: 60px;
    overflow: hidden;
    position: absolute;
    right: 0;
    z-index: 10;

    &.active {
        background-color: $white;
        z-index: 30;

        .top {
            background-color: $black;
            transform: translateY(6px) rotate(225deg);
        }

        .bot {
            background-color: $black;
            transform: translateY(-6px) rotate(-225deg);
        }

        .mid {
            opacity: 0;
        }
    }

    .line {
        @include transition(all, $animTime, $easeOutExpo);

        background: $white;
        display: block;
        height: 2px;
        margin: 4px auto;
        opacity: 1;
        position: relative;
        top: 0;
        transform: none;
        will-change: transform;
        width: 22px;
    }

    @include desktop {
        display: none;
    }
}

.sub-nav {
    background: none;
    border: 0;
    color: $white;
    display: block;
    height: 45px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
    z-index: 5;

    &.icon-arrow {
        &:before,
        &:after {
            @include transition(all, $animTime, $easeOutExpo);

            background: $grey;
            content: "";
            display: block;
            height: 8px;
            position: absolute;
            margin: auto;
            top: 20px;
            width: 2px;
        }

        &:before {
            left: 19px;
            transform: rotate(-45deg);
        }

        &:after {
            right: 19px;
            transform: rotate(45deg);
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }

            &:after {
                transform: rotate(135deg);
            }
        }
    }
}
