// footer styles
.footer {
    background-color: #0a102a;
    color: $white;

    p {
        margin: 20px 0;
    }

    a {
        @include transition((background-color, color), $animTime, $easeOutExpo);

        align-items: center;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $white;
        color: #0a102a;
        display: flex;
        height: 32px;
        justify-content: center;
        margin: 1em 0;
        text-decoration: none;
        text-align: center;
        width: 32px;

        &:hover,
        &:focus {
            background-color: #0a102a;
            color: $white;
        }
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        margin: auto;
        max-width: 960px;
        padding: 0 20px;

        @include large-desktop {
            max-width: 1160px;
        }
    }
}
