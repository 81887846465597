// navigation styles
#navigation {
    @include transition(height, $animTime, $easeOutExpo);
    @include clearfix;

    // height: 45px;
    height: 60px;
    position: absolute;
    right: 0;
    // top: 60px;
    top: 10px;
    width: 100%;
    width: auto;
    z-index: 5;

    @include desktop {
        top: 70px;
    //     width: auto;

    //     .sub-nav,
    //     .lvl2,
    //     .lvl3 {
    //         display: none;
    //     }
    }


    &.active {
        z-index: 25;
    }

    ol {
        @include clearfix;

        // background: $white;
        margin: 0 $gutter;
        margin-bottom: 0;
        overflow: hidden;
        padding: 0;

        li {
            // @include transition(all, 0.75s, $easeOutExpo);

            // @for $i from 1 through 10 {
            //     &:nth-child(#{$i}) {
            //         transition-delay: 0.1s * $i, 0.1s * $i;
            //     }
            // }

            // opacity: 0;
            // overflow: hidden;
            // position: relative;
            // transform: translateY(-30px);
            will-change: transform;
            vertical-align: top;

            @include desktop {
                transform: none;
                opacity: 1;
            }
        }

        a {
            color: $black;
            display: inline-block;
            font-size: em(18);
            font-weight: $bold;
            margin-bottom: 0;
            overflow: hidden;
            padding: 10px;
            position: relative;
            text-decoration: none;
            vertical-align: top;
            width: 100%;

            &.active {
                background: lighten($black, 20%);
            }

            span {
                position: relative;
                z-index: 5;
            }

            @include desktop {
                color: $white;
            }
        }
    }

    .nav {
        @include clearfix;

        height: 0;
        width: 100%;

        &:after {
            @include transition(opacity, $animTime, $easeOutExpo);

            background: rgba(0, 0, 0, 0.5);
            content: '';
            height: inherit;
            left: 0;
            opacity: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 5;
        }

        &.active {
            height: 100%;

            &:after {
                opacity: 1;
            }
        }

        @include desktop {
            height: auto;
            margin: auto;
            max-width: 960px;
        }


        @include large-desktop {
            max-width: 1160px;
        }
    }

    .lvl2,
    .lvl3 {
        display: none;
        margin: 0;

        &.active {
            & > li {
                transform: none;
                opacity: 1;
            }
        }

        a {
            border-bottom: 0;
            width: 100%;
        }

        .sub-nav {
            &.icon-arrow {
                &:before,
                &:after {
                    background: $white;
                }
            }
        }
    }

    .lvl1 {
        @include material-shadow(0);
        @include transition(all, $animTime, $easeOutExpo);
        @include normalise-list;

        // float: right;
        list-style: none;
        // max-width: 300px;
        overflow: auto;
        position: relative;
        top: 0;
        // transform: translateX(100%);
        will-change: transform;
        width: 100%;
        z-index: 10;

        @include desktop {
        //     transform: none;
        //     float: none;
        //     max-width: none;

            background: none;
            height: auto !important;
            overflow: visible;

            &:first-child {
                & > a {
                    padding-left: 0;
                }
            }

            li {
                border-top: 0;
                float: left;
            }
        }


        &.active {
            @include material-shadow(2);

            transform: none;

            & > li {
                transform: none;
                opacity: 1;
            }
        }

        & > li {
            // border-bottom: 1px solid #ddd;

            // @include desktop {
                // border-bottom: 0;
                display: block;
                margin: 0 20px;
                overflow: visible;
            // }


            &:last-child {
                a {
                    // @include desktop {
                        @include transition((background-color, color), $animTime, $easeOutExpo);

                        background-color: $white;
                        color: #111e51;
                        font-weight: $bold;
                        margin: auto;
                        padding: 5px 20px;
                        width: auto;

                        @include desktop {
                            font-size: em(18);
                            padding: 10px 20px;
                        }

                        &:hover,
                        &:focus {
                            background-color: #111e51;
                            color: $white;
                            text-decoration:none;
                        }

                        .-book-a-session & {
                            background-color: #111e51;
                            color: $white;

                            &:hover,
                            &:focus {
                                background-color: $white;
                                color: #111e51;

                            }
                        }
                    // }
                }
            }
        }

        a {
            padding: 10px $gutter;

            &:hover,
            &:focus {
                text-decoration: underline;
            }

            &.active {
                background: lighten($black, 20%);
            }
        }
    }

    .lvl2 {
        background: lighten($black, 30%);

        a {
            color: $white;
            padding: 12px 20px 12px 30px;
        }

        @include desktop {
            position: absolute;
            width: 200px;
        }
    }

    .lvl3 {
        background: lighten($black, 20%);

        li {
            border-top: 1px solid lighten($black, 40%);
        }

        a {
            color: $white;
            padding: 12px 20px 12px 40px;
        }
    }
}
