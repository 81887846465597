@import url("https://fonts.googleapis.com/css?family=Montserrat:400,900|Open+Sans:300,400,700");
.vh {
  visibility: hidden;
}

.sr-only, .form-item label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.desktop-only {
  display: none !important;
}

@media screen and (min-width: 980px), print {
  .desktop-only {
    display: block !important;
  }
}

.tablet-only {
  display: none !important;
}

@media screen and (min-width: 768px), print {
  .tablet-only {
    display: block !important;
  }
}

.mobile-only {
  display: none !important;
}

@media (max-width: 767px) {
  .mobile-only {
    display: block !important;
  }
}

@media screen and (min-width: 980px), print {
  .no-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 768px), print {
  .no-tablet {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .no-mobile {
    display: none !important;
  }
}

.column-break {
  margin-bottom: 100vh !important;
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.pull-left {
  float: left;
  margin-right: 15px;
}

.pull-right {
  float: right;
  margin-left: 15px;
}

.no-border {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?-p3pgtp");
  src: url("../fonts/icomoon.eot?#iefix-p3pgtp") format("embedded-opentype"), url("../fonts/icomoon.woff?-p3pgtp") format("woff"), url("../fonts/icomoon.ttf?-p3pgtp") format("truetype"), url("../fonts/icomoon.svg?-p3pgtp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .breadcrumbs li:after, .form-item.-checkbox label:after, .datepicker:after, .section__intro button[type="button"]:after, .section__projects .project__location p:before {
  font-family: "icomoon", sans-serif;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-left-thin:before {
  content: "\e907";
}

.icon-chevron-right-thin:before {
  content: "\e908";
}

.icon-location:before {
  content: "\e909";
}

.icon-linkedin:before {
  content: "\e906";
}

.icon-calendar:before {
  content: "\e903";
}

.icon-chevron:before {
  content: "\e904";
}

.icon-dropdown:before {
  content: "\e905";
}

.icon-cross:before {
  content: "\e900";
}

.icon-chevron-down:before {
  content: "\e901";
}

.icon-chevron-right:before {
  content: "\e902";
}

.icon-done:before {
  content: "\e28a";
}

.icon-print:before {
  content: "\e2c1";
}

/* =======================================================================
Global
========================================================================== */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}

body {
  font: 16px/1.618 sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin: 0 !important;
}

@media screen and (min-width: 768px), print {
  body {
    font-family: 'Open Sans', sans-serif;
  }
}

h1, h2, h3, h4, h5, h6,
address {
  word-break: break-word;
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 3em;
  line-height: 1.05em;
}

h2 {
  font-size: 2.25em;
  line-height: 1.25;
}

h3 {
  font-size: 1.75em;
  line-height: 1.25em;
}

h4 {
  font-size: 1.125em;
  line-height: 1.222222em;
}

h5 {
  font-size: 1.0625em;
  font-style: italic;
}

h6 {
  font-size: 1em;
  font-style: italic;
}

blockquote {
  font-weight: 300;
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.458333em;
}

menu, ol, ul {
  margin-bottom: 20px;
  padding: 0 0 0 40px;
}

ol, ul {
  margin: auto;
}

table {
  background-color: transparent;
  border: 1px solid #9e9e9e;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  width: 100%;
}

table tr {
  background-color: #efefef;
}

table tr:nth-child(even) {
  background-color: #e2e2e2;
}

table th, table td {
  border: 1px solid #fff;
  padding: 5px 10px;
  vertical-align: top;
}

table th {
  background: #757575;
  font-size: 1.25em;
  color: #ffffff;
  text-align: left;
}

img {
  display: block;
  height: auto;
  margin: 0 auto 10px;
  max-width: 100%;
  width: 100%;
}

@media screen and (min-width: 768px), print {
  img {
    margin: 0 0 10px;
    width: auto;
  }
}

address {
  margin: 0 0 10px;
  word-break: break-word;
}

p {
  word-break: break-word;
}

a {
  color: #000000;
  margin-bottom: 10px;
}

hr {
  margin: 40px 0;
}

::-ms-clear {
  height: 22px;
  width: 22px;
}

::-webkit-input-placeholder {
  /* Chrome */
  color: #a9a9a9;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #a9a9a9;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #a9a9a9;
  opacity: 1;
}

:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: #a9a9a9;
  opacity: 1;
}

/* =======================================================================
Layout
========================================================================== */
.-book-a-session {
  background-color: #1b2e74;
}

#main {
  margin-top: 45px;
  position: relative;
}

@media screen and (min-width: 980px), print {
  #main {
    margin-top: 0;
  }
}

.intro p {
  font-size: 1.125em;
  font-weight: bold;
}

.offline {
  height: calc(100vh - 91px);
  text-align: center;
}

@media (max-width: 767px) {
  .offline {
    height: calc(100vh - 45px);
  }
}

.offline svg {
  width: 100px;
}

.offline .container .wrap {
  transform: translate(-50%, -50%);
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  width: 50%;
}

.skip-link {
  background-color: #ffffff;
  font-size: 0.75em;
  font-weight: bold;
  left: 0px;
  padding: 5px;
  position: absolute;
  top: 0px;
  text-align: center;
  width: 100%;
  z-index: 15;
}

.skip-link:not(:focus) {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  width: 1px;
}

.backstretch {
  background-color: #9e9e9e;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.grid [class^="col-"], .grid [class*=" col-"] {
  background: #dedede;
  border: 1px solid #9e9e9e;
  text-align: center;
}

[class^="col-"], [class*=" col-"] {
  margin-bottom: 20px;
  padding: 0 20px;
  width: 100%;
}

[class^="col-"]:last-child, [class*=" col-"]:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px), print {
  [class^="col-"], [class*=" col-"] {
    float: left;
    margin-bottom: 0;
    padding: 0 15px;
  }
}

@media screen and (min-width: 768px), print {
  .col-1 {
    width: 8.333333%;
  }
  .col-2 {
    width: 16.666667%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.333333%;
  }
  .col-5 {
    width: 41.666667%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.333333%;
  }
  .col-8 {
    width: 66.666667%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.333333%;
  }
  .col-11 {
    width: 91.666667%;
  }
  .col-12 {
    width: 100%;
  }
  .col-offset-1 {
    margin-left: 8.333333%;
  }
  .col-offset-2 {
    margin-left: 16.666667%;
  }
  .col-offset-3 {
    margin-left: 25%;
  }
  .col-offset-4 {
    margin-left: 33.333333%;
  }
  .col-offset-5 {
    margin-left: 41.666667%;
  }
  .col-offset-6 {
    margin-left: 50%;
  }
  .col-offset-7 {
    margin-left: 58.333333%;
  }
  .col-offset-8 {
    margin-left: 66.666667%;
  }
  .col-offset-9 {
    margin-left: 75%;
  }
  .col-offset-10 {
    margin-left: 83.333333%;
  }
  .col-offset-11 {
    margin-left: 91.666667%;
  }
  .col-offset-12 {
    margin-left: 100%;
  }
}

.container {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
  height: 100%;
  margin: auto;
  max-width: 960px;
  padding: 0 20px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.container:before, .container:after {
  content: '';
  display: table;
}

.container:after {
  clear: both;
}

@media screen and (min-width: 980px), print {
  .container {
    padding: 0 15px;
  }
}

@media screen and (min-width: 1200px), print {
  .container {
    max-width: 1160px;
  }
}

.container .wrap {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
  margin: 0 -20px;
  padding: 0;
}

.container .wrap:before, .container .wrap:after {
  content: '';
  display: table;
}

.container .wrap:after {
  clear: both;
}

@media screen and (min-width: 768px), print {
  .container .wrap {
    margin: 0 -15px;
  }
}

.btn, .btn-print-table {
  transition-property: background;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  background: #000000;
  border: 0;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  padding: 10px 15px;
  position: relative;
  text-decoration: none;
  width: auto;
}

.btn:hover, .btn-print-table:hover, .btn:focus, .btn-print-table:focus {
  background: #404040;
}

.btn:focus, .btn-print-table:focus {
  outline: 0;
}

.btn:disabled, .btn-print-table:disabled {
  background: #ebeaea;
  color: #b8b7b7;
  cursor: not-allowed;
}

.btn svg.ripple circle, .btn-print-table svg.ripple circle {
  fill: #ffffff;
}

svg.ripple {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

svg.ripple circle {
  fill: #ffffff;
  opacity: 0;
}

.card {
  transition-property: opacity, top;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  position: relative;
  top: 150px;
}

.card.-show {
  opacity: 1;
  top: 0;
}

.card.-up {
  top: -150px;
}

.card__wrapper {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #ffffff;
  border-radius: 2px;
  min-height: 100px;
  padding: 15px 20px;
}

@media screen and (min-width: 768px), print {
  .card {
    margin-bottom: 30px;
  }
}

.shadow-z0 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.shadow-z1 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.shadow-z2 {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.shadow-z3 {
  box-shadow: 0 8px 9px -5px rgba(0, 0, 0, 0.2), 0 15px 22px 2px rgba(0, 0, 0, 0.14), 0 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.shadow-z4 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 20px 31px 3px rgba(0, 0, 0, 0.14), 0 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.shadow-z5 {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.toaster__wrap {
  bottom: 25px;
  left: 25px;
  max-width: 440px;
  position: fixed;
  width: 100%;
  z-index: 25;
}

@media (max-width: 767px) {
  .toaster__wrap {
    max-width: 260px;
  }
}

.toaster__card {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #323232;
  border-radius: 2px;
  color: #ffffff;
  margin-bottom: 10px;
  opacity: 0;
  padding: 15px 24px;
  padding-right: 50px;
  transform: scale(0.75);
  transform-origin: left bottom;
}

.toaster__card:only-child, .toaster__card:last-child {
  margin-bottom: 0;
}

.toaster__card.-refresh {
  padding-right: 135px;
}

.toaster__card p {
  margin: 0;
}

.toaster__card button {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  height: 20px;
  left: auto;
  padding: 0;
  right: 10px;
  width: 20px;
}

.toaster__card .btn-refresh {
  color: #ffeb3b;
  font-size: 0.8125em;
  right: 50px;
  width: auto;
}

.toaster__card .icon {
  display: block;
  font-size: 20px;
}

select {
  background: none;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #8c8c8c;
  font-size: 1em;
  height: 40px;
  padding: 10px 0;
  width: 100%;
}

.loader {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #ffffff;
  border-radius: 50%;
  height: 50px;
  position: relative;
  width: 50px;
}

.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.loader .circular {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  animation: rotate 2s linear infinite;
  height: 75%;
  margin: auto;
  transform-origin: center center;
  width: 75%;
}

.loader .path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  100%, 0% {
    stroke: #000000;
  }
}

.header {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #1b2e74;
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

@media screen and (min-width: 980px), print {
  .header {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    background-color: transparent;
    height: auto;
    position: absolute;
  }
  .header.hide {
    top: -108px !important;
  }
}

.logo {
  margin-top: 15px;
  padding: 0;
  position: absolute;
  z-index: 5;
}

@media screen and (min-width: 980px), print {
  .logo {
    margin-top: 60px;
    position: relative;
  }
}

.logo a {
  color: #ffffff;
  display: block;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
  max-width: 100px;
  text-decoration: none;
}

@media screen and (min-width: 980px), print {
  .logo a {
    max-width: 210px;
  }
}

.logo a svg {
  width: 100%;
}

.menu {
  transition-property: background-color;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  background-color: transparent;
  border: 0;
  height: 60px;
  padding: 8px;
  width: 60px;
  overflow: hidden;
  position: absolute;
  right: 0;
  z-index: 10;
}

.menu.active {
  background-color: #ffffff;
  z-index: 30;
}

.menu.active .top {
  background-color: #000000;
  transform: translateY(6px) rotate(225deg);
}

.menu.active .bot {
  background-color: #000000;
  transform: translateY(-6px) rotate(-225deg);
}

.menu.active .mid {
  opacity: 0;
}

.menu .line {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  background: #ffffff;
  display: block;
  height: 2px;
  margin: 4px auto;
  opacity: 1;
  position: relative;
  top: 0;
  transform: none;
  will-change: transform;
  width: 22px;
}

@media screen and (min-width: 980px), print {
  .menu {
    display: none;
  }
}

.sub-nav {
  background: none;
  border: 0;
  color: #ffffff;
  display: block;
  height: 45px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 45px;
  z-index: 5;
}

.sub-nav.icon-arrow:before, .sub-nav.icon-arrow:after {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  background: #9e9e9e;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  margin: auto;
  top: 20px;
  width: 2px;
}

.sub-nav.icon-arrow:before {
  left: 19px;
  transform: rotate(-45deg);
}

.sub-nav.icon-arrow:after {
  right: 19px;
  transform: rotate(45deg);
}

.sub-nav.icon-arrow.active:before {
  transform: rotate(-135deg);
}

.sub-nav.icon-arrow.active:after {
  transform: rotate(135deg);
}

.banner {
  color: #ffffff;
  height: 150px;
  margin-bottom: 20px;
}

.banner h2 {
  margin-top: 20px;
}

@media screen and (min-width: 768px), print {
  .banner {
    height: 180px;
  }
}

.breadcrumbs {
  height: 100%;
  margin: auto;
  max-width: 960px;
  padding: 0 20px;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 980px), print {
  .breadcrumbs {
    padding: 0 15px;
  }
}

@media screen and (min-width: 1200px), print {
  .breadcrumbs {
    max-width: 1160px;
  }
}

.breadcrumbs ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.breadcrumbs li {
  display: inline-block;
  vertical-align: top;
}

.breadcrumbs li a {
  text-decoration: none;
}

.breadcrumbs li a:hover, .breadcrumbs li a:focus {
  text-decoration: underline;
}

.breadcrumbs li:after {
  content: "\e902";
  margin: 0 10px;
  position: relative;
  top: 3px;
}

.breadcrumbs li:last-child {
  font-weight: bold;
}

.breadcrumbs li:last-child:after {
  content: "";
}

/* Slider */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before {
  display: table;
  content: '';
}

.slick-track:after {
  display: table;
  content: '';
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.datepicker {
  display: inline-block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.datepicker table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}

.datepicker table th, .datepicker table td {
  width: calc(100% / 7);
  padding: 0.0625rem;
}

.datepicker table th {
  color: #ccc;
  text-transform: uppercase;
  font-size: 0.5rem;
  line-height: 4;
  font-weight: bold;
  text-align: center;
}

.datepicker__wrapper {
  color: #333;
  border-radius: 0.125rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0.5rem;
  position: relative;
  z-index: 1;
  background: white;
  border: 1px solid #ddd;
  width: 16rem;
}

.datepicker__wrapper::after {
  content: '';
  display: table;
  clear: both;
}

.datepicker:not(.is-inline) .datepicker__wrapper {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
  margin: 0.25rem 0;
}

.datepicker__header {
  position: relative;
  text-align: center;
  padding: 0.25rem;
  margin: -0.5rem -0.5rem 0;
  border-bottom: 1px solid #ddd;
}

.datepicker__title {
  display: inline-block;
  padding: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: bold;
}

.datepicker__prev, .datepicker__next {
  display: block;
  cursor: pointer;
  position: relative;
  outline: none;
  width: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  background: no-repeat center / 60%;
  opacity: 0.8;
}

.datepicker__prev:hover, .datepicker__next:hover {
  opacity: 1;
}

.datepicker__prev {
  float: left;
}

.datepicker__next {
  float: right;
}

.datepicker__prev.is-disabled, .datepicker__next.is-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.4;
}

.datepicker__time {
  padding: 0.5rem 1rem;
  margin: 0 -0.5rem;
  border-bottom: 1px solid #ddd;
  font-size: 0.75rem;
  text-align: right;
  background: #fafafa;
}

.datepicker__label {
  margin-right: 1rem;
  color: #aaa;
  float: left;
}

.datepicker__field {
  display: inline-block;
  margin: 0 0.125rem;
}

.datepicker__day {
  color: #333;
}

.datepicker__day div {
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  background: transparent;
  position: relative;
}

.datepicker__day div:hover {
  background: #f8f8f8;
}

.datepicker__day.is-today .datepicker__daynum {
  text-decoration: underline;
}

.datepicker__day.is-selected div {
  background: #eee;
}

.datepicker__day.is-selected .datepicker__daynum {
  font-weight: bold;
}

.datepicker__day.is-disabled, .datepicker__day.is-otherMonth {
  cursor: default;
  pointer-events: none;
  color: #ddd;
}

.datepicker__day.is-disabled.is-selected .datepicker__daynum, .datepicker__day.is-otherMonth.is-selected .datepicker__daynum {
  color: rgba(0, 0, 0, 0.2);
}

.datepicker__day.is-disabled.is-selected div, .datepicker__day.is-otherMonth.is-selected div {
  background: #ddd;
}

.datepicker__daynum {
  display: block;
  font-size: 0.75rem;
  line-height: 2rem;
  text-align: center;
}

.form-item {
  margin-bottom: 15px;
}

.form-item input[type="text"],
.form-item input[type="email"],
.form-item input[type="password"],
.form-item input[type="date"],
.form-item textarea {
  transition-property: border-bottom, color, outline;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  border: none;
  border-bottom: 1px solid #8c8c8c;
  font-size: 1em;
  font-weight: 300;
  padding: 10px;
  width: 100%;
}

.form-item input[type="text"]:focus,
.form-item input[type="email"]:focus,
.form-item input[type="password"]:focus,
.form-item input[type="date"]:focus,
.form-item textarea:focus {
  border-bottom: 1px solid #000000;
  color: #000000;
}

.form-item input[type="text"]:disabled,
.form-item input[type="email"]:disabled,
.form-item input[type="password"]:disabled,
.form-item input[type="date"]:disabled,
.form-item textarea:disabled {
  background: none;
  border-bottom: 1px dotted #8c8c8c;
}

.form-item input[type="text"]:disabled:hover, .form-item input[type="text"]:disabled:focus,
.form-item input[type="email"]:disabled:hover,
.form-item input[type="email"]:disabled:focus,
.form-item input[type="password"]:disabled:hover,
.form-item input[type="password"]:disabled:focus,
.form-item input[type="date"]:disabled:hover,
.form-item input[type="date"]:disabled:focus,
.form-item textarea:disabled:hover,
.form-item textarea:disabled:focus {
  cursor: not-allowed;
}

.form-item input[type="text"],
.form-item input[type="email"],
.form-item input[type="password"],
.form-item input[type="date"] {
  height: 40px;
}

.form-item input[type="submit"] {
  transition-property: background-color, color;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #111e51;
  cursor: pointer;
  font-size: 1.125em;
  font-weight: 700;
  padding: 10px 20px;
  width: 100%;
}

@media screen and (min-width: 980px), print {
  .form-item input[type="submit"] {
    max-width: 170px;
  }
}

.form-item input[type="submit"]:hover, .form-item input[type="submit"]:focus {
  background-color: #111e51;
  color: #ffffff;
}

.form-item select {
  background-color: #ffffff;
  border: 0;
  padding: 10px;
}

.form-item input[type="radio"],
.form-item input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.form-item input[type="radio"]:focus + label:before,
.form-item input[type="checkbox"]:focus + label:before {
  border: 2px solid #000000;
}

.form-item input[type="radio"]:checked + label:before,
.form-item input[type="checkbox"]:checked + label:before {
  border: 2px solid #000000;
}

.form-item.-radio-button label, .form-item.-checkbox label {
  cursor: pointer;
  display: block;
  font-size: 1em;
  line-height: 1.3;
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}

.form-item.-radio-button label:before, .form-item.-checkbox label:before {
  transition-property: border, background;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  border: 2px solid #6f6f6f;
  content: "";
  display: inline-block;
  height: 18px;
  left: 0;
  position: absolute;
  width: 18px;
}

.form-item.-radio-button label:after, .form-item.-checkbox label:after {
  transition-property: border, top, transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  height: 18px;
  position: absolute;
  margin: auto;
  transform: scale(0);
  width: 18px;
}

.form-item.-radio-button label:before {
  border-radius: 50%;
  margin-right: 18px;
  position: absolute;
}

.form-item.-radio-button label:after {
  background: transparent;
  border-radius: 50%;
  content: "";
  display: block;
  left: 0;
  top: 0;
}

.form-item.-radio-button input[type="radio"]:checked + label:after {
  background: #000000;
  transform: scale(0.45);
}

.form-item.-checkbox label:before {
  border-radius: 2px;
}

.form-item.-checkbox label:after {
  color: transparent;
  content: "\e28a";
  display: inline-block;
  height: 10px;
  left: 1px;
  top: 6px;
  width: 10px;
}

.form-item.-checkbox input[type="checkbox"]:checked + label:before {
  background: #000000;
}

.form-item.-checkbox input[type="checkbox"]:checked + label:after {
  color: #ffffff;
  top: 0;
  transform: scale(1);
}

.form-item.-select .form-item__wrap {
  background-color: #ffffff;
}

.form-item.-submit {
  display: flex;
  justify-content: flex-end;
}

.datepicker {
  width: 100%;
}

.datepicker:after {
  bottom: 0;
  content: "\e903";
  color: #758291;
  font-size: 25px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 10px;
  top: 0;
  width: 25px;
}

.section__wrap {
  margin: auto;
  max-width: 960px;
  padding: 0 20px;
}

@media screen and (min-width: 768px), print {
  .section__wrap {
    display: flex;
  }
}

@media screen and (min-width: 1200px), print {
  .section__wrap {
    max-width: 1160px;
  }
}

.section__intro {
  align-items: center;
  background-image: url("../images/hero-banner.jpg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  display: flex;
  color: #ffffff;
  height: 100vh;
  justify-content: center;
  position: relative;
  text-align: center;
}

@media screen and (min-width: 768px), print {
  .section__intro {
    background-position: center;
    /*height: 600px;*/
  }
}

.section__intro:after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 20%, rgba(0, 0, 0, 0.25) 100%);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media screen and (min-width: 980px), print {
  .section__intro h2 {
    margin-top: 150px;
  }
}

.section__intro button[type="button"] {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-size: 0.9375em;
  margin-top: 100px;
  text-transform: uppercase;
  letter-spacing: 0.92em;
}

.section__intro button[type="button"]:after {
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  content: "\e904";
  display: block;
  font-size: 40px;
  margin: 10px auto 0;
  transform: none;
  width: 40px;
}

.section__intro button[type="button"]:hover:after, .section__intro button[type="button"]:focus:after {
  transform: translateY(10px);
}

.section__intro .section__wrap {
  display: block;
  z-index: 5;
}

@media screen and (min-width: 980px), print {
  .section__intro .section__wrap {
    max-width: 50%;
  }
}

.section__carousel {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.section__carousel .slick-arrow {
  background-color: transparent;
  border: 0;
  bottom: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 40px;
  height: 40px;
  display: block;
  overflow: hidden;
  margin: auto;
  padding: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

@media screen and (min-width: 980px), print {
  .section__carousel .slick-arrow {
    font-size: 70px;
    height: 70px;
    width: 70px;
  }
}

.section__carousel .slick-prev {
  left: 10px;
}

@media screen and (min-width: 980px), print {
  .section__carousel .slick-prev {
    left: 125px;
    z-index: 1000;
  }
}

.section__carousel .slick-next {
  right: 10px;
}

@media screen and (min-width: 980px), print {
  .section__carousel .slick-next {
    right: 125px;
  }
}

.section__carousel .slick-dots {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.section__carousel .slick-dots button[type="button"] {
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #ffffff;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  height: 14px;
  margin: 0 4px;
  opacity: 0.5;
  width: 14px;
}

.section__carousel .slick-dots button[type="button"]:hover, .section__carousel .slick-dots button[type="button"]:focus {
  opacity: 1;
}

.section__carousel .slick-dots .slick-active button[type="button"] {
  opacity: 1;
}

@media screen and (min-width: 980px), print {
  .section__carousel .slick-list {
    padding: 0 175px !important;
  }
}

.section__carousel .slick-slide {
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  padding: 0 50px;
  opacity: 0.25;
}

@media screen and (min-width: 768px), print {
  .section__carousel .slick-slide {
    padding: 0 80px;
  }
}

.section__carousel .slick-slide.slick-current {
  opacity: 1;
}

.section__highlights {
  background-color: #4151ed;
  background-position: left;
  background-size: cover;
  color: #ffffff;
}

.section__highlights h3 {
  font-size: 2.25em;
  margin: 0;
}

@media screen and (min-width: 980px), print {
  .section__highlights h3 {
    margin: 30px 0;
  }
}

.section__highlights p {
  font-size: 1.125em;
  margin-bottom: 30px;
}

.section__highlights .section__wrap {
  padding: 50vh 0 0;
}

@media screen and (min-width: 768px), print {
  .section__highlights .section__wrap {
    padding: 0;
  }
}

.section__highlights .section__col {
  background-color: #4151ed;
  padding: 60px 70px 60px 20px 40px 20px;
  position: relative;
}

@media screen and (min-width: 768px), print {
  .section__highlights .section__col {
    width: 40%;
  }
}

.section__highlights .section__col:before, .section__highlights .section__col:after {
  display: none;
}

@media screen and (min-width: 768px), print {
  .section__highlights .section__col:before, .section__highlights .section__col:after {
    display: block;
  }
}

.section__highlights .section__col:before {
  background-color: #4151ed;
  content: '';
  height: 100%;
  left: -100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.section__highlights .section__col:after {
  background-color: #4151ed;
  content: '';
  height: 100%;
  right: -100px;
  position: absolute;
  top: 0;
  transform: skew(14deg);
  width: 200px;
  z-index: 1;
}

.section__highlights .section__content {
  padding: 30px 20px 0;
  position: relative;
  z-index: 5;
}

@media screen and (min-width: 768px), print {
  .section__highlights .section__content {
    padding: 30px 30px 0;
  }
}

.section__highlights .btn, .section__highlights .btn-print-table {
  background-color: #111e51;
  color: #ffffff;
  display: inline-block;
  font-size: 1.125em;
  font-weight: 700;
  margin: 0 auto 30px;
  padding: 15px 20px;
  width: auto;
}

.section__highlights .btn:hover, .section__highlights .btn-print-table:hover, .section__highlights .btn:focus, .section__highlights .btn-print-table:focus {
  background-color: #ffffff;
  color: #111e51;
}

.section__projects {
  background-color: #1b2e74;
  color: #ffffff;
  padding-top: 60px;
  padding-bottom: 40px;
  text-align: center;
  display: none;
}

.section__projects h3 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.section__projects h4 {
  font-size: 1.5625em;
}

.section__projects p {
  font-size: 1.125em;
}

.section__projects .section__wrap {
  display: block;
  max-width: none;
}

@media screen and (min-width: 980px), print {
  .section__projects .section__wrap {
    padding: 0;
  }
}

.section__projects .project__icons {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.section__projects .project__icons li {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 0 8px;
  max-width: 44px;
}

.section__projects .project__icons img {
  margin-bottom: 0;
}

.section__projects .project__location {
  font-size: 1em;
  margin-top: 30px;
  margin-bottom: 40px;
}

.section__projects .project__location p {
  font-size: inherit;
  margin: 0;
}

.section__projects .project__location p:before {
  content: "\e909";
  display: inline-block;
  font-size: 12px;
  margin-right: 5px;
}

.section__projects .project__location span {
  font-style: italic;
}

.section__services {
  background-color: #111e51;
  color: #ffffff;
}

.section__services h3 {
  font-size: 1.5625em;
  margin: 20px 0;
}

.section__services .section__col {
  padding: 20px;
  text-align: center;
}

@media screen and (min-width: 768px), print {
  .section__services .section__col {
    padding: 60px 35px;
  }
}

.section__services .section__img {
  height: 80px;
  margin: auto;
  overflow: hidden;
  width: 80px;
}

.section__contact {
  background-color: #111e51;
  background-position: -60vw center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: #ffffff;
  padding-top: 60px;
  padding-bottom: 40px;
  text-align: center;
}

.section__contact h2 {
  font-size: 2.5em;
  margin: 0 0 10px;
}

.section__contact p {
  font-size: 1.125em;
}

.section__contact a {
  color: inherit;
}

.section__contact hr {
  margin: 40px auto;
  width: 120px;
}

.section__contact .section__wrap {
  flex-direction: column;
  padding: 0 20px;
}

@media screen and (min-width: 768px), print {
  .section__contact .section__wrap {
    max-width: 50%;
    padding: 0;
  }
}

.section__contact .cta {
  font-size: 1.625em;
  font-weight: 300;
}

.section__contact .btn, .section__contact .btn-print-table {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #111e51;
  cursor: pointer;
  display: inline-block;
  font-size: 1.125em;
  font-weight: 700;
  margin: 10px auto 0;
  padding: 15px 20px;
  width: auto;
}

.section__contact .btn:hover, .section__contact .btn-print-table:hover, .section__contact .btn:focus, .section__contact .btn-print-table:focus {
  background-color: #111e51;
  color: #ffffff;
}

@media screen and (min-width: 768px), print {
  .section__book {
    display: flex;
    margin-top: 75px;
  }
}

@media screen and (min-width: 980px), print {
  .section__book {
    margin-top: 200px;
  }
}

.section__book .section__wrap {
  padding: 0;
}

.section__book .section__content {
  background-color: #111e51;
  color: #ffffff;
  display: block;
  padding: 20px;
}

@media screen and (min-width: 768px), print {
  .section__book .section__content {
    margin-bottom: 80px;
    padding: 60px 70px 60px 130px;
    width: 50%;
  }
}

.section__book .section__content h2 {
  margin-top: 0;
}

.section__book .section__image {
  background-image: url("../images/background-form.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 150px;
  position: relative;
}

@media screen and (min-width: 768px), print {
  .section__book .section__image {
    height: 700px;
    margin-top: 50px;
    right: -60px;
    width: 50%;
  }
}

.footer {
  background-color: #0a102a;
  color: #ffffff;
}

.footer p {
  margin: 20px 0;
}

.footer a {
  transition-property: background-color, color;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #ffffff;
  color: #0a102a;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 1em 0;
  text-decoration: none;
  text-align: center;
  width: 32px;
}

.footer a:hover, .footer a:focus {
  background-color: #0a102a;
  color: #ffffff;
}

.footer__wrap {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 960px;
  padding: 0 20px;
}

@media screen and (min-width: 1200px), print {
  .footer__wrap {
    max-width: 1160px;
  }
}

#navigation {
  transition-property: height;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
  height: 60px;
  position: absolute;
  right: 0;
  top: 10px;
  width: 100%;
  width: auto;
  z-index: 5;
}

#navigation:before, #navigation:after {
  content: '';
  display: table;
}

#navigation:after {
  clear: both;
}

@media screen and (min-width: 980px), print {
  #navigation {
    top: 70px;
  }
}

#navigation.active {
  z-index: 25;
}

#navigation ol {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
  margin: 0 20px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 0;
}

#navigation ol:before, #navigation ol:after {
  content: '';
  display: table;
}

#navigation ol:after {
  clear: both;
}

#navigation ol li {
  will-change: transform;
  vertical-align: top;
}

@media screen and (min-width: 980px), print {
  #navigation ol li {
    transform: none;
    opacity: 1;
  }
}

#navigation ol a {
  color: #000000;
  display: inline-block;
  font-size: 1.125em;
  font-weight: 700;
  margin-bottom: 0;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-decoration: none;
  vertical-align: top;
  width: 100%;
}

#navigation ol a.active {
  background: #333333;
}

#navigation ol a span {
  position: relative;
  z-index: 5;
}

@media screen and (min-width: 980px), print {
  #navigation ol a {
    color: #ffffff;
  }
}

#navigation .nav {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
  height: 0;
  width: 100%;
}

#navigation .nav:before, #navigation .nav:after {
  content: '';
  display: table;
}

#navigation .nav:after {
  clear: both;
}

#navigation .nav:after {
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  background: rgba(0, 0, 0, 0.5);
  content: '';
  height: inherit;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

#navigation .nav.active {
  height: 100%;
}

#navigation .nav.active:after {
  opacity: 1;
}

@media screen and (min-width: 980px), print {
  #navigation .nav {
    height: auto;
    margin: auto;
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px), print {
  #navigation .nav {
    max-width: 1160px;
  }
}

#navigation .lvl2,
#navigation .lvl3 {
  display: none;
  margin: 0;
}

#navigation .lvl2.active > li,
#navigation .lvl3.active > li {
  transform: none;
  opacity: 1;
}

#navigation .lvl2 a,
#navigation .lvl3 a {
  border-bottom: 0;
  width: 100%;
}

#navigation .lvl2 .sub-nav.icon-arrow:before, #navigation .lvl2 .sub-nav.icon-arrow:after,
#navigation .lvl3 .sub-nav.icon-arrow:before,
#navigation .lvl3 .sub-nav.icon-arrow:after {
  background: #ffffff;
}

#navigation .lvl1 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  list-style-type: none;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  position: relative;
  top: 0;
  will-change: transform;
  width: 100%;
  z-index: 10;
}

@media screen and (min-width: 980px), print {
  #navigation .lvl1 {
    background: none;
    height: auto !important;
    overflow: visible;
  }
  #navigation .lvl1:first-child > a {
    padding-left: 0;
  }
  #navigation .lvl1 li {
    border-top: 0;
    float: left;
  }
}

#navigation .lvl1.active {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
  transform: none;
}

#navigation .lvl1.active > li {
  transform: none;
  opacity: 1;
}

#navigation .lvl1 > li {
  display: block;
  margin: 0 20px;
  overflow: visible;
}

#navigation .lvl1 > li:last-child a {
  transition-property: background-color, color;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #ffffff;
  color: #111e51;
  font-weight: 700;
  margin: auto;
  padding: 5px 20px;
  width: auto;
}

@media screen and (min-width: 980px), print {
  #navigation .lvl1 > li:last-child a {
    font-size: 1.125em;
    padding: 10px 20px;
  }
}

#navigation .lvl1 > li:last-child a:hover, #navigation .lvl1 > li:last-child a:focus {
  background-color: #111e51;
  color: #ffffff;
  text-decoration: none;
}

.-book-a-session #navigation .lvl1 > li:last-child a {
  background-color: #111e51;
  color: #ffffff;
}

.-book-a-session #navigation .lvl1 > li:last-child a:hover, .-book-a-session #navigation .lvl1 > li:last-child a:focus {
  background-color: #ffffff;
  color: #111e51;
}

#navigation .lvl1 a {
  padding: 10px 20px;
}

#navigation .lvl1 a:hover, #navigation .lvl1 a:focus {
  text-decoration: underline;
}

#navigation .lvl1 a.active {
  background: #333333;
}

#navigation .lvl2 {
  background: #4d4c4c;
}

#navigation .lvl2 a {
  color: #ffffff;
  padding: 12px 20px 12px 30px;
}

@media screen and (min-width: 980px), print {
  #navigation .lvl2 {
    position: absolute;
    width: 200px;
  }
}

#navigation .lvl3 {
  background: #333333;
}

#navigation .lvl3 li {
  border-top: 1px solid #666666;
}

#navigation .lvl3 a {
  color: #ffffff;
  padding: 12px 20px 12px 40px;
}

.table-wrap {
  margin-bottom: 20px;
  overflow: hidden;
  padding: 6px 0;
  position: relative;
  width: 100%;
}

.table-wrap ::-webkit-scrollbar {
  height: 8px;
  width: 4px;
}

.table-wrap ::-webkit-scrollbar-thumb {
  background: #9e9e9e;
}

.table-wrap:before, .table-wrap:after {
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  content: '';
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 50px;
}

.table-wrap:before {
  border-radius: 0 10px 10px 0 / 0 50% 50% 0;
  box-shadow: 4px 0 10px black;
  right: 100%;
}

.table-wrap:after {
  border-radius: 10px 0 0 10px / 50% 0 0 50%;
  box-shadow: -4px 0 10px black;
  left: 100%;
}

.table-wrap.left, .table-wrap.right {
  transition-property: border;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.table-wrap.left {
  border-left: 1px solid #f4f4f4;
}

.table-wrap.left:before {
  opacity: 0.25;
}

.table-wrap.right {
  border-right: 1px solid #f4f4f4;
}

.table-wrap.right:after {
  opacity: 0.25;
}

.table-wrap.-clean:before, .table-wrap.-clean:after {
  display: none;
}

.table-wrap.-clean.right {
  border-right: 0;
  padding: 0;
}

.table-wrap.-clean .table-responsive {
  border: 0;
  overflow: visible;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: 1px solid #dadada;
  margin-bottom: 15px;
  overflow-y: hidden;
  overflow-x: scroll;
  width: 100%;
}

.table-responsive table {
  margin-bottom: 0;
}

.btn-print-table {
  transition-property: position;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 1.6em;
  max-width: 200px;
  padding: 10px;
  width: 100%;
}

@media screen and (min-width: 980px), print {
  .btn-print-table {
    display: none;
  }
}
