// form styles
.datepicker {
    display: inline-block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        border: 0;

        th, td {
            width: calc(100% / 7);
            padding: 0.0625rem;
        }

        th {
            color: #ccc;
            text-transform: uppercase;
            font-size: 0.5rem;
            line-height: 4;
            font-weight: bold;
            text-align: center;
        }
    }
}

.datepicker__wrapper {
    color: #333;
    border-radius: 0.125rem;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0.5rem;
    position: relative;
    z-index: 1;
    background: white;
    border: 1px solid #ddd;
    width: 16rem;

    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

.datepicker:not(.is-inline) .datepicker__wrapper {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
    margin: 0.25rem 0;
}

.datepicker__header {
    position: relative;
    text-align: center;
    padding: 0.25rem;
    margin: -0.5rem -0.5rem 0;
    border-bottom: 1px solid #ddd;
}

.datepicker__title {
    display: inline-block;
    padding: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: bold;
}

.datepicker__prev, .datepicker__next {
    display: block;
    cursor: pointer;
    position: relative;
    outline: none;
    width: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    background: no-repeat center / 60%;
    opacity: 0.8;
}

.datepicker__prev:hover, .datepicker__next:hover {
    opacity: 1;
}

.datepicker__prev {
    float: left;
}

.datepicker__next {
    float: right;
}

.datepicker__prev.is-disabled, .datepicker__next.is-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
}

.datepicker__time {
    padding: 0.5rem 1rem;
    margin: 0 -0.5rem;
    border-bottom: 1px solid #ddd;
    font-size: 0.75rem;
    text-align: right;
    background: #fafafa;
}

.datepicker__label {
    margin-right: 1rem;
    color: #aaa;
    float: left;
}

.datepicker__field {
    display: inline-block;
    margin: 0 0.125rem;
}

.datepicker__day {
    color: #333;

    div {
        cursor: pointer;
        display: block;
        box-sizing: border-box;
        border: 0;
        margin: 0;
        background: transparent;
        position: relative;

        &:hover {
            background: #f8f8f8;
        }
    }

    &.is-today .datepicker__daynum {
        text-decoration: underline;
    }

    &.is-selected {
        div {
            background: #eee;
        }

        .datepicker__daynum {
            font-weight: bold;
        }
    }

    &.is-disabled, &.is-otherMonth {
        cursor: default;
        pointer-events: none;
        color: #ddd;
    }

    &.is-disabled.is-selected .datepicker__daynum, &.is-otherMonth.is-selected .datepicker__daynum {
        color: rgba(0, 0, 0, 0.2);
    }

    &.is-disabled.is-selected div, &.is-otherMonth.is-selected div {
        background: #ddd;
    }
}

.datepicker__daynum {
    display: block;
    font-size: 0.75rem;
    line-height: 2rem;
    text-align: center;
}

.form {}

.form-item {
    margin-bottom: 15px;

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="date"],
    textarea {
        @include transition((border-bottom, color, outline), $animTime, $easeOutExpo);

        border: none;
        border-bottom: 1px solid $border-color;
        font-size: em(16);
        font-weight: $light;
        padding: 10px;
        width: 100%;

        &:focus {
            border-bottom: 1px solid $black;
            color: $black;
        }

        &:disabled {
            background: none;
            border-bottom: 1px dotted $border-color;

            &:hover,
            &:focus {
                cursor: not-allowed;
            }
        }
    }

    label {
        @extend .sr-only;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="date"] {
        height: 40px;
    }

    input[type="submit"] {
        @include transition((background-color, color), $animTime, $easeOutExpo);

        background-color: $white;
        border: 1px solid $white;
        color: #111e51;
        cursor: pointer;
        font-size: em(18);
        font-weight: $bold;
        padding: 10px 20px;
        width: 100%;

        @include desktop {
            max-width: 170px;
        }

        &:hover,
        &:focus {
            background-color: #111e51;
            color: $white;
        }
    }

    select {
        background-color: $white;
        border: 0;
        padding: 10px;
    }

    input[type="radio"],
    input[type="checkbox"] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;

        &:focus + label {
            &:before {
                border: 2px solid $black;
            }
        }

        &:checked + label {
            &:before {
                border: 2px solid $black;
            }
        }
    }

    &.-radio-button,
    &.-checkbox {
        label {
            cursor: pointer;
            display: block;
            font-size: em(16);
            line-height: 1.3;
            padding-left: 30px;
            position: relative;
            margin-bottom: 10px;
            width: 100%;

            &:before {
                @include transition((border, background), $animTime, $easeOutExpo);

                border: 2px solid #6f6f6f;
                content: "";
                display: inline-block;
                height: 18px;
                left: 0;
                position: absolute;
                width: 18px;
            }

            &:after {
                @include transition((border, top, transform), $animTime, $easeOutExpo);

                height: 18px;
                position: absolute;
                margin: auto;
                transform: scale(0);
                width: 18px;
            }
        }
    }

    &.-radio-button {
        label {
            &:before {
                border-radius: 50%;
                margin-right: 18px;
                position: absolute;
            }

            &:after {
                background: transparent;
                border-radius: 50%;
                content: "";
                display: block;
                left: 0;
                top: 0;
            }
        }

        input[type="radio"] {
            &:checked + label {
                &:after {
                    background: $black;
                    transform: scale(0.45);
                }
            }
        }
    }

    &.-checkbox {
        label {
            &:before {
                border-radius: 2px;
            }

            &:after {
                @extend %icomoon;

                color: transparent;
                content: "\e28a";
                display: inline-block;
                height: 10px;
                left: 1px;
                top: 6px;
                width: 10px;
            }
        }

        input[type="checkbox"] {
            &:checked + label {
                &:before {
                    background: $black;
                }

                &:after {
                    color: $white;
                    top: 0;
                    transform: scale(1);
                }
            }
        }
    }

    &.-select {
        .form-item__wrap {
            background-color: $white;
        }
    }

    &.-submit {
        display: flex;
        justify-content: flex-end;
    }
}

.datepicker {
    width: 100%;

    &:after {
        @extend %icomoon;

        bottom: 0;
        content: "\e903";
        color: #758291;
        font-size: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 10px;
        top: 0;
        width: 25px;
    }
}