// Common Font Weights From Google Fonts
$thin: 100;
$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;
$ultra-bold: 900;

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,900|Open+Sans:300,400,700");

// font-family: 'Montserrat', sans-serif;
// font-family: 'Open Sans', sans-serif;

// Icomoon Fonts
@font-face {
    font-family: 'icomoon';
    src: url("../fonts/icomoon.eot?-p3pgtp");
    src: url("../fonts/icomoon.eot?#iefix-p3pgtp") format("embedded-opentype"), url("../fonts/icomoon.woff?-p3pgtp") format("woff"), url("../fonts/icomoon.ttf?-p3pgtp") format("truetype"), url("../fonts/icomoon.svg?-p3pgtp#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

%icomoon {
    font-family: "icomoon", sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
    @extend %icomoon;
}

.icon-chevron-left-thin:before {
    content: "\e907";
}

.icon-chevron-right-thin:before {
    content: "\e908";
}

.icon-location:before {
    content: "\e909";
}

.icon-linkedin:before {
    content: "\e906";
}

.icon-calendar:before {
    content: "\e903";
}

.icon-chevron:before {
    content: "\e904";
}

.icon-dropdown:before {
    content: "\e905";
}

.icon-cross:before {
    content: "\e900";
}

.icon-chevron-down:before {
    content: "\e901";
}

.icon-chevron-right:before {
    content: "\e902";
}

.icon-done:before {
    content: "\e28a";
}

.icon-print:before {
    content: "\e2c1";
}
