// table-preview styles
.table-wrap {
    margin-bottom: 20px;
    overflow: hidden;
    padding: 6px 0;
    position: relative;
    width: 100%;

    ::-webkit-scrollbar {
        height: 8px;
        width: 4px
    }

    ::-webkit-scrollbar-thumb {
        background: $grey;
    }

    &:before,
    &:after {
        @include transition(opacity, 0.3s, $easeOutExpo);

        content: '';
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 50px;
    }

    &:before {
        border-radius: 0 10px 10px 0 / 0 50% 50% 0;
        box-shadow: 4px 0 10px black;
        right: 100%;
    }

    &:after {
        border-radius: 10px 0 0 10px / 50% 0 0 50%;
        box-shadow: -4px 0 10px black;
        left: 100%;
    }

    &.left,
    &.right {
        @include transition(border, 0.3s, $easeOutExpo);
    }

    &.left {
        border-left: 1px solid #f4f4f4;

        &:before {
            opacity: 0.25;
        }
    }

    &.right {
        border-right: 1px solid #f4f4f4;

        &:after {
            opacity: 0.25;
        }
    }

    &.-clean {
        &:before,
        &:after {
            display: none;
        }

        &.right {
            border-right: 0;
            padding: 0;
        }

        .table-responsive {
            border: 0;
            overflow: visible;
        }
    }
}

.table-responsive {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #dadada;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;

    table {
        margin-bottom: 0;
    }
}

.btn-print-table {
    @include transition(position, $animTime, $easeOutExpo);

    @extend .btn;

    font-size: $defaultFontSize;
    max-width: 200px;
    padding: 10px;
    width: 100%;

    @include desktop {
        display: none;
    }
}
